<template>
  <div>
    <p style="font-size: 20px; font-weight: 600; margin: 20px 0 20px 0">
      向离职店长的所有客户发送短信通知
    </p>
    <div
      style="
        display: flex;
        justify-content: start;
        margin: 20px 0 0 5px;
        border-bottom: 1px solid #ddd;
        width: 85%;
        padding-bottom: 20px;
      "
    >
      <el-select
        :clearable="true"
        v-model="city"
        placeholder="选择城市"
        class="rankStyle inputStyle"
        @change="choseCity4(city)"
        style="height: 30px !important"
      >
        <el-option
          v-for="(item, index) in cities"
          :key="index"
          :label="item.city"
          :value="item.city_id"
        ></el-option>
      </el-select>
      <el-select
        :clearable="true"
        v-model="shopper"
        placeholder="选择店长"
        class="rankStyle inputStyle"
        @change="choseShopkeeper4(shopper)"
        filterable
      >
        <el-option
          v-for="(item, index) in shoppers2"
          :key="index"
          :label="item.username"
          :value="item.user_id"
        ></el-option>
      </el-select>
    </div>
    <avue-crud
      :data="managerList"
      :option="option4"
      v-model="obj4"
      style="margin-top: 15px"
    >
      <template slot-scope="{ row, type, size }" slot="menu">
        <el-button :size="size" :type="type" @click="startSendMsg(row)"
          >发送短信</el-button
        >
      </template>
    </avue-crud>
    <!-- 超时未接单任务完成模态框 -->
    <el-dialog
      title="选择新店长"
      :visible.sync="shopperDialog"
      width="45%"
      z-index="100"
      center
      top="15%"
    >
      <div style="display: flex; padding: 0 15% 5% 15%">
        <div
          style="margin-right: 5%; width: 130px; font-size: 16px; color: #999"
        >
          选择新店长
        </div>
        <div>
          <el-select
            v-model="shopper2"
            placeholder="请选择转派店长"
            style="width: 90%"
            filterable
          >
            <el-option
              v-for="(item, index) in Shopkeepers2"
              :key="index"
              :label="item.usernamestorename"
              :value="item.user_id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="shopperDialog = false"
          >取 消</el-button
        >
        <el-button type="primary" size="medium" plain @click="sendToClient"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  //   props: {
  //     mobile: Number
  //   },
  data() {
    return {
      active: 0,
      city: "",
      cities: [],
      shopper: "",
      shopper2: "",
      shoppers: [],
      store: "",
      stores: [],
      stores2: [],
      shoppers2: [],
      create_time2: "",
      count2: 0,
      managerList: [],
      option4: {
        border: true,
        size: "medium",
        // menu: false,
        page: false,
        width: "90%",
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        columnBtn: false, //列显隐按钮
        refreshBtn: false, //刷新按钮
        column: [
          {
            label: "店长",
            prop: "username",
          },

          {
            label: "店长id",
            prop: "user_id",
            hide: true,
          },
          {
            label: "店铺",
            prop: "store_name",
          },
          {
            label: "城市",
            prop: "city",
          },
          {
            label: "订单总数",
            prop: "count",
          },
        ],
      },
      obj4: {},
      currentPage2: 1,
      time2: 0,
      shopperDialog: false,
    };
  },
  created() {
    this.getCity();
  },
  watch: {},
  methods: {
    getCity() {
      //获取城市
      this.util.get(this.HOST + "/Common/cheng", {}).then((res) => {
        if (res.code == 200) {
          this.cities = res.data;
          this.cities5 = res.data;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    choseCity4(city) {
      //选择城市-发送短信
      this.shopper = "";
      this.getShopper2(city);
    },
    // getShopper(city) {
    //   //获取店长
    //   this.util
    //     .get(this.HOST + "/Common/user", {
    //       city_id: city
    //     })
    //     .then(res => {
    //       if (res.code == 200) {
    //         this.shoppers = res.data;
    //       } else {
    //         this.$message({
    //           showClose: true,
    //           message: res.msg,
    //           type: "error",
    //           duration: 1200,
    //           offset: 90
    //         });
    //       }
    //     });
    // },
    getShopper2(city) {
      //获取店长
      this.util
        .get(this.HOST + "/Order/getUserList", {
          city_id: city,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers2 = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getStores(city) {
      //获取选取城市的店铺
      this.util
        .get(this.HOST + "/Common/store", {
          city_id: city,
        })
        .then((res) => {
          //console.log(res, "stores++++");
          if (res.code == 200) {
            this.stores = res.data;
            this.stores2 = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getManagers() {
      //获取店长列表
      this.managerList = [];
      this.util
        .get(this.HOST + "/Order/orderQuitList", {
          city_id: this.city,
          user_id: this.shopper,
        })
        .then((res) => {
          if (res.code == 200) {
            // let arr = []
            // let a  = arr.push(res.data)
            // this.managerList = a[0]
            // this.managerList = res.data
            this.managerList.push(res.data);
            //console.log(this.managerList, "this.managerList++");
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    choseShopkeeper4(value) {
      //选择店长之后的操作-施工超期
      this.getManagers();
    },
    startSendMsg(row) {
      this.shopperDialog = true;
    },
    sendToClient() {
      //发送短信
      this.$loading(this.util.opload);
      this.util
        .get(this.HOST + "/Order/orderQuit", {
          city_id: this.city,
          user_id: this.shopper,
          shopwoners: this.shopper2,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: "短信发送成功！",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            this.shopper2 = "";
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
  },
  computed: {
    Shopkeepers() {
      var _this = this;
      var Shopkeepers = [];
      this.shoppers.map((item) => {
        if (item.username.search(_this.shop) != -1) {
          Shopkeepers.push(item);
        }
      });
      return Shopkeepers;
    },
    Shopkeepers2() {
      var _this = this;
      var Shopkeepers2 = [];
      this.shoppers2.map((item) => {
        if (item.username.search(_this.shop) != -1) {
          Shopkeepers2.push(item);
        }
      });
      return Shopkeepers2;
    },
  },
};
</script>
<style scoped>
.content {
  margin: 0 auto;
  background: white;
  padding: 10px 0 0 30px;
  border-radius: 8px;
  height: 100%;
}
.child {
  width: 24%;
  margin-bottom: 10%;
}
.number {
  font-size: 26px;
  /* text-align: center; */
  color: #999;
  margin: 5% 0 0 25%;
}
.title {
  font-size: 16px;
  margin-top: 20px;
  text-align: left;
  color: #666;
  width: 145px;
}
.sort {
  margin-bottom: -20px;
}
.rankStyle {
  margin-left: 20px;
}
.selectedTime {
  color: #409eff;
}
.returnIcon:hover {
  color: cornflowerblue;
}
div /deep/ .avue-crud__menu {
  min-height: 0px !important;
}
.el-icon-warning:hover span {
  display: block;
}
::v-deep .datePicker {
  width: 256px;
  height: 32px !important;
  line-height: 32px !important;
}
::v-deep .littleBox input {
  height: 32px !important;
  line-height: 32px !important;
  width: 160px;
}
.active1 {
  display: none;
}
.active2 {
  display: inline;
}
</style>
